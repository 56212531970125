import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function ShapeAnims({ node }) {
	const $footer = document.querySelector('.footer');
	if ($footer) {
		const $topLeft = $footer.querySelector('#top-left');
		const $bottomLeft = $footer.querySelector('#bottom-left');
		const $topMiddle = $footer.querySelector('#top-middle');
		const $bottomMiddle = $footer.querySelector('#bottom-middle');

		gsap.set($topLeft, { y: '200%', immediateRender: true });
		gsap.set($topMiddle, { y: '200%', immediateRender: true });
		gsap.set($bottomLeft, { y: '100%', immediateRender: true });
		gsap.set($bottomMiddle, { y: '100%', immediateRender: true });

		const anim = gsap.to([$topLeft, $bottomLeft, $topMiddle, $bottomMiddle], {
			duration: 1,
			y: 0,
			paused: true,
		});

		// Create the animation timeline
		gsap.timeline({
			scrollTrigger: {
				trigger: $footer,
				start: 'top 50%',
				end: '50% 50%',
				onEnter: () => anim.play(),
			},
		});
	}

	const animations = [...document.querySelectorAll('[data-animation]')] || [];
	animations.forEach(el => {
		const $el = el;
		const $animation = $el.getAttribute('data-animation');
		const $delay = $el.getAttribute('data-delay') || 0.5;
		const $duration = $el.getAttribute('data-duration') || 0.25;
		const $ease = $el.getAttribute('data-ease') || 'power1.inOut';

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: $el,
				start: 'top 80%',
				end: 'bottom 20%',
				scrub: false,
				markers: false,
			},
		});

		switch ($animation) {
			case 'inspire':
				tl.to($el.querySelector('#r-bottom-right'), {
					duration: $duration,
					transformOrigin: '50% 50%',
					rotation: 90,
					ease: $ease,
					delay: $delay,
				})
					.to($el.querySelector('#s-bottom-left'), {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: -90,
						ease: $ease,
						delay: $delay,
					})
					.to($el.querySelector('#p-bottom-left'), {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: -90,
						ease: $ease,
						delay: $delay,
					});
			case 'spark':
				gsap.set('#k-bottom-right', {
					transformOrigin: '50% 50%',
					rotation: 90,
				});
				tl.to('#s-top-right', {
					duration: $duration,
					delay: 0.5,
					transformOrigin: '50% 50%',
					rotation: -90,
					ease: $ease,
				})
					.to('#r-bottom-right', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 90,
						ease: $ease,
						delay: $delay,
					})
					.to('#a-bottom-left', {
						duration: $duration,
						xPercent: 100,
						ease: $ease,
						delay: $delay,
					})
					.to('#k-bottom-right', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 0,
						ease: $ease,
						delay: $delay,
					});
			case 'team':
				gsap.set('#t-top-right', {
					transformOrigin: '50% 50%',
					rotation: 90,
				});
				tl.to('#t-bottom', {
					duration: $duration,
					xPercent: 50,
					ease: $ease,
					delay: $delay,
				})
					.to('#e-top-right', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: -90,
						ease: $ease,
						delay: $delay,
					})
					.to('#m-top-left', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 90,
						ease: $ease,
						delay: $delay,
					})
					.to('#t-top-right', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 0,
						ease: $ease,
						delay: $delay,
					});
			case 'connect':
				gsap.set('#y-top-left', {
					transformOrigin: '50% 50%',
					rotation: 90,
				});
				tl.to('#c-bottom-left', {
					duration: $duration,
					transformOrigin: '50% 50%',
					rotation: 90,
					ease: $ease,
					delay: $delay,
				})
					.to('#n-top-left', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 90,
						ease: $ease,
						delay: $delay,
					})
					.to('#o-bottom-right', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: -90,
						ease: $ease,
						delay: $delay,
					})
					.to('#y-top-left', {
						duration: $duration,
						transformOrigin: '50% 50%',
						rotation: 90,
						ease: $ease,
						delay: $delay,
					})
					.to(
						'#y-top-right',
						{
							duration: $duration,
							transformOrigin: '50% 50%',
							rotation: -90,
							ease: $ease,
							delay: 0,
						},
						'<'
					);
		}
	});
}

export default ShapeAnims;
