import { eventBus } from '@spon/plugins';
import { gsap } from 'gsap';

import verticalLoop from '@/utils/infiniteVerticalScroll';

function NavScroll({ node }) {
	let wheel1 = verticalLoop(document.querySelectorAll('.nav__item--svg'), {
		repeat: -1,
		paused: true,
		center: true,
		draggable: false, // I'm just being fancy
		inertia: true, // even fancier
	});

	// Select all .nav__link elements
	const navLinks = document.querySelectorAll('.nav__link');
	const navSVGs = document.querySelectorAll('.nav__item--svg');

	// Loop through each .nav__link element and add a mouseover event listener
	navLinks.forEach((link, index) => {
		link.addEventListener('mouseover', e => {
			e.preventDefault();
			console.log(`Hovered over element at index: ${index}`);
			spin(wheel1, index);
		});
	});

	// this function picks a random index from the wheel and animates there, adding an extra 2 revolutions. Tweak or parameterize the duration, ease, etc. if you'd like.
	function spin(wheel, activeIndex = 0) {
		// pick a random index from the Array of elements in this wheel
		// let randomIndex = gsap.utils.random(0, wheel.elements.length, 1);
		// now animate to that index, adding an extra 2 full revolutions.
		navSVGs.forEach((svg, index) => {
			svg.classList.add('opacity-30');
		});
		navSVGs[activeIndex].classList.remove('opacity-30');

		wheel.toIndex(activeIndex, {
			duration: 0.25,
			revolutions: 0,
			ease: 'power2.inOut',
		});
	}

	eventBus.on('menu:open', () => {
		gsap.fromTo(
			'.nav__list--svg',
			{ opacity: 0 },
			{
				opacity: 1,
			}
		);

		let randomIndex = gsap.utils.random(0, navSVGs.length, 1);

		wheel1.toIndex(randomIndex, {
			duration: 1,
			revolutions: 2,
			ease: 'power2.inOut',
		});
	});

	eventBus.on('menu:close', () => {
		[...node.querySelectorAll('.nav__item--svg')].forEach(svg => {
			svg.classList.add('opacity-30');
		});
	});
}

export default NavScroll;
