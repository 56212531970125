import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import Splitting from 'splitting';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function TextAnims({ node }) {
	// Split texts
	Splitting();

	// Initialize Lenis smooth scrolling
	const initSmoothScrolling = () => {
		return ScrollSmoother.create({
			smooth: 1,
			effects: true,
			normalizeScroll: false,
			smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
			ignoreMobileResize: true,
		});
	};

	const fx2Titles = [
		...document.querySelectorAll('[data-splitting][data-effect2]'),
	];

	const fx6Titles = [
		...document.querySelectorAll('[data-splitting][data-effect6]'),
	];

	const scroll = () => {
		fx2Titles.forEach(title => {
			title.classList.add('opacity-100');
			const chars = title.querySelectorAll('.char');

			gsap.fromTo(
				chars,
				{
					'will-change': 'opacity, transform',
					opacity: 0,
					yPercent: 110,
					scaleY: 2.3,
					scaleX: 0.7,
					transformOrigin: '50% 0%',
				},
				{
					duration: 1,
					ease: 'back.inOut(2)',
					opacity: 1,
					yPercent: 0,
					scaleY: 1,
					scaleX: 1,
					stagger: 0.03,
					scrollTrigger: {
						trigger: title,
						start: 'center bottom+=50%',
						end: 'bottom top+=40%',
						scrub: true,
					},
				}
			);
		});

		fx6Titles.forEach(title => {
			title.classList.add('opacity-100');
			const chars = title.querySelectorAll('.char');

			gsap.fromTo(
				chars,
				{
					'will-change': 'opacity, transform',
					opacity: 0,
					rotationX: -90,
					yPercent: 50,
				},
				{
					ease: 'power1.inOut',
					opacity: 1,
					rotationX: 0,
					yPercent: 0,
					stagger: {
						each: 0.02,
						from: 0,
					},
					scrollTrigger: {
						trigger: chars,
						start: 'center bottom+=40%',
						end: 'bottom center-=30%',
						scrub: false,
					},
				}
			);
		});
	};

	// Lenis (smooth scrolling)
	initSmoothScrolling();

	// GSAP Scroll Triggers
	scroll();
}

export default TextAnims;
