import {
	withPlugins,
	eventBus,
	domEvents,
	device,
	inview,
} from '@spon/plugins';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';

function Header({ node, name, plugins: { addEvents, inview } }) {
	if (!node) return;

	// Headroom
	const options = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	};

	const headroom = new Headroom(node, options);

	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${windowTop * -1}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const nav = toggle({
		button: node.querySelector('.o-burger'),
		name,
		activeClass: 'is-active',
	});

	/*
	 *		Functions
	 */

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'header' || target.closest('#header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.add('is-open');

		eventBus.emit('menu:open');

		lock.capture();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.remove('is-open');

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});

	eventBus.on('page:exited', nav.close);
	// Main Mobile Menu END

	function onKeyDown(event) {
		const { key } = event;
		if (key === 'Escape') {
			if (nav.isOpen) {
				nav.close();
			}
		}
	}

	/*
	 *		Init
	 */
	nav.init();
	headroom.init();

	/*
	 *		Inview / Nav colour
	 */
	// inview.settings = {
	// 	rootMargin: '0px 0px -40% 0px',
	// };

	// watch some other nodes
	const $hero = document.querySelector('#hero') || null;
	if ($hero) {
		inview.observe([$hero], {
			enter: () => {
				console.log('hero enter');
				node.classList.remove('text-black', 'bg-white'); // handled with CSS.
			},
			exit: () => {
				console.log('hero exit');
				node.classList.add('text-black', 'bg-white');
			},
		});
	} else {
		node.classList.add('text-black', 'bg-white');
	}

	/*
	 *		Events
	 */
	addEvents(document.body, {
		click: ({ target }) => {
			if (target.id === 'header' || target.closest('#header')) return;
		},
		keydown: onKeyDown,
	});

	return () => {};
}

export default withPlugins(domEvents, device, inview)(Header);
